import { WeaponType, MedallionType } from '../server/constants';
import { ClientState } from './clientState';

/**
 * Notifications describe game events such as frags, wins, etc that go to the
 * HUD.
 */
const Notifications = {
	_nextID() {
		return Math.floor(Math.random() * 1e5);
	},

	logFrag(
		state: ClientState,
		data: { src: string; dest: string; weapon?: WeaponType }
	) {
		const src = state.players[data.src];
		const dest = state.players[data.dest];
		if (src && dest) {
			document.dispatchEvent(
				new CustomEvent('frag', {
					detail: {
						key: 'frag',
						id: Notifications._nextID(),
						src,
						dest,
						weapon: data.weapon
					}
				})
			);
		} else {
			console.warn('Trying to log frag but player was not found', { data });
		}
	},

	logCapture(state: ClientState, data: { by: string[] }) {
		const players = data.by.map(p => state.players[p]).filter(p => p);
		if (players.length > 0) {
			document.dispatchEvent(
				new CustomEvent('capture', {
					detail: {
						key: 'capture',
						id: Notifications._nextID(),
						players
					}
				})
			);
		}
	},

	logDefence(state: ClientState, data: { by: string }) {
		const player = state.players[data.by];
		if (player) {
			document.dispatchEvent(
				new CustomEvent('defence', {
					detail: {
						key: 'defence',
						id: Notifications._nextID(),
						player
					}
				})
			);
		}
	},

	logMedallionPicked(
		state: ClientState,
		data: { by: string; medallion_type: MedallionType }
	) {
		const player = state.players[data.by];
		if (player) {
			document.dispatchEvent(
				new CustomEvent('medallion_picked', {
					detail: {
						key: 'medallion_picked',
						id: Notifications._nextID(),
						player,
						medallion_type: data.medallion_type
					}
				})
			);
		}
	},

	logWin(state: ClientState, data: any) {
		const red = data.red.map((p: any) => ({ ...p, ...state.players[p.id] }));
		const blue = data.blue.map((p: any) => ({ ...p, ...state.players[p.id] }));
		document.dispatchEvent(
			new CustomEvent('roundwon', {
				detail: { ...data, key: 'won', red, blue }
			})
		);
	},

	roundStart() {
		document.dispatchEvent(new CustomEvent('roundstart'));
	},

	playerDisconnected(state: ClientState, id: string) {
		const player = state.players[id];
		document.dispatchEvent(
			new CustomEvent('gamemessage', {
				detail: {
					id: Notifications._nextID(),
					body: `${player.name} left the game`
				}
			})
		);
	},

	joinedTeam(state: ClientState, data: { id: string }) {
		const player = state.players[data.id];
		if (player) {
			document.dispatchEvent(
				new CustomEvent('gamemessage', {
					detail: {
						id: Notifications._nextID(),
						body: `${player.name} joined team ${player.team}`
					}
				})
			);
		}
	}
};

export default Notifications;
