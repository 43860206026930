import React from 'react';
import '../styles/admin_panel.css';

const AdminPanel: React.FC<{
	startGame: () => void;
	resetGame: () => void;
}> = ({ startGame, resetGame }) => {
	return (
		<div className="admin-panel btn-group">
			<button className="btn btn-info" onClick={startGame}>
				Start Game
			</button>
			<button className="btn btn-warning" onClick={resetGame}>
				Reset Game
			</button>
		</div>
	);
};

export default AdminPanel;
