import Phaser from 'phaser';
import Player from '../../server/models/player';
import { ClientState } from '../clientState';
import {
	GameState,
	MedallionType,
	invuln_time,
	MedallionTextures
} from '../../server/constants';

const health_bar_width = 150;
const charge_bar_width = 150;
const bar_alpha = 0.7;
const orb_tints = {
	red: 0xfa4d4d,
	blue: 0x6fb5f7,
	'': 0xeeeeee
};
const hit_tints = {
	red: 0xffc2c2,
	blue: 0xc2dbff,
	'': 0xffffff
};
const lobby_message = 'Waiting for players...';

export default class HUDScene extends Phaser.Scene {
	game_countdown = 0;
	state!: ClientState;
	health_bar!: Phaser.GameObjects.Rectangle;
	charge_bar!: Phaser.GameObjects.Rectangle;
	build_bar!: Phaser.GameObjects.Rectangle;
	build_tween?: Phaser.Tweens.Tween;
	respawn_timer!: Phaser.GameObjects.Text;
	victory_points!: {
		red: Phaser.GameObjects.Text;
		blue: Phaser.GameObjects.Text;
	};
	max_hp!: number;
	max_charge!: number;
	max_build!: number;
	orb_indicators: Record<number, Phaser.GameObjects.Shape> = {};
	charge = 0;
	build = 0;
	held_medallion!: MedallionType;
	ammo_text!: Phaser.GameObjects.Text;
	medallion_image?: Phaser.GameObjects.Image;

	constructor() {
		super({ key: 'HUDScene' });
	}

	create({ player, state }: { player: Player; state: ClientState }) {
		this.state = state;
		// Health bar outline
		const health_outline = this.add.rectangle(30, 30, health_bar_width + 2, 20);
		this.health_bar = this.add.rectangle(
			31,
			31,
			health_bar_width,
			18,
			0x78ff76
		);
		health_outline.setOrigin(0);
		health_outline.isStroked = true;
		this.health_bar.setOrigin(0);
		this.health_bar.setAlpha(bar_alpha);
		this.max_hp = player.max_hp;
		this.max_charge = player.max_charge;
		this.max_build = (player as any).max_build || 0;

		const charge_outline = this.add.rectangle(30, 50, charge_bar_width + 2, 8);
		this.charge_bar = this.add.rectangle(31, 51, 0, 6, 0xffffff);
		charge_outline.setOrigin(0);
		this.charge_bar.setOrigin(0);
		this.charge_bar.setAlpha(bar_alpha);
		charge_outline.isStroked = true;

		if (player.className === 'word') {
			const build_outline = this.add.rectangle(30, 58, health_bar_width + 2, 8);
			build_outline.isStroked = true;
			build_outline.setOrigin(0);
			this.build_bar = this.add.rectangle(31, 59, 0, 6, 0x000fff);
			this.build_bar.setOrigin(0);
			this.build_bar.setAlpha(bar_alpha);
		}

		this.held_medallion = player.held_medallion;
		this.setHeldMedallion(this.held_medallion);
		this.ammo_text = this.add.text(220, 33, 'x', { font: 'bold 18px Courier' });
		this.ammo_text.setAlpha(0);

		this.respawn_timer = this.add.text(400, 200, lobby_message, {
			font: 'bold 26px Courier'
		});
		this.respawn_timer.setOrigin(0.5);
		this.respawn_timer.alpha = Number(state.game_state === GameState.Lobby);

		const orb_view = this.add.graphics({
			fillStyle: { color: 0x0, alpha: 0.3 }
		});
		orb_view.fillRoundedRect(695, 523, 80, 60, 3);
		Object.entries(state.orbs).forEach(([key, orb]) => {
			const orb_indicator = this.add.circle(
				695 + orb.x / 10,
				523 + orb.y / 10,
				5,
				orb_tints[orb.state]
			);
			orb_indicator.setState(orb.state);
			this.orb_indicators[key] = orb_indicator;
		});

		const red_vp_back = this.add.circle(380, 45, 20, orb_tints.red);
		const blue_vp_back = this.add.circle(420, 45, 20, orb_tints.blue);
		red_vp_back.setAlpha(0.7);
		blue_vp_back.setAlpha(0.7);
		const red_vp_text = this.add.text(
			380,
			45,
			String(state.victory_points.red),
			{ font: 'bold 18px Courier' }
		);
		red_vp_text.setOrigin(0.5);
		const blue_vp_text = this.add.text(
			420,
			45,
			String(state.victory_points.blue),
			{ font: 'bold 18px Courier' }
		);
		blue_vp_text.setOrigin(0.5);
		this.victory_points = { red: red_vp_text, blue: blue_vp_text };
	}

	setCharge(charge: number) {
		this.charge = charge;
		this.charge_bar.width = charge_bar_width * (charge / this.max_charge);
	}

	setBuild(build: number) {
		if (this.build === this.max_build && build === 0) {
			// Invuln popped.
			this.build_tween = this.add.tween({
				targets: [this.build_bar],
				duration: invuln_time,
				delay: 0,
				onComplete: () => {
					delete this.build_tween;
				},
				width: {
					getStart: () => charge_bar_width,
					getEnd: () => 0
				}
			});
		} else {
			this.build_bar.width = charge_bar_width * (build / this.max_build);
		}
		this.build = build;
	}

	setFireAmmo(ammo: number) {
		this.ammo_text.alpha = ammo > 0 ? 0.8 : 0;
		this.ammo_text.setText(`x${ammo}`);
	}

	setHeldMedallion(medallion: MedallionType) {
		const [x, y] = [204, 44];
		this.medallion_image?.destroy();
		switch (medallion) {
			case MedallionType.None:
				delete this.medallion_image;
				break;
			default:
				this.medallion_image = this.add.image(
					x,
					y,
					MedallionTextures[medallion]
				);
				this.medallion_image.alpha = 0.8;
				this.medallion_image.setDisplaySize(30, 30);
				break;
		}
		this.held_medallion = medallion;
	}

	setRespawnTimer(timer: number) {
		if (this.state.game_state === GameState.Match) {
			this.respawn_timer.alpha = Number(!!timer);
			this.respawn_timer.setText(timer ? `Respawn in ${timer}` : lobby_message);
		}
	}

	setGameState(game_state: GameState) {
		if (game_state === GameState.Match) {
			this.respawn_timer.alpha = 0;
		} else if (game_state === GameState.Lobby && this.respawn_timer) {
			this.respawn_timer.alpha = 1;
			this.respawn_timer.setText(lobby_message);
		}
	}

	setGameCountdown(timer: number) {
		this.game_countdown = timer;
		if (this.respawn_timer) {
			this.respawn_timer.setText(
				timer ? `Game starts in ${timer}` : lobby_message
			);
		}
	}

	updateHP(hp: number) {
		this.health_bar.width = health_bar_width * (hp / this.max_hp);
		if (hp === 0 && this.build_tween) {
			this.build_tween.stop();
			delete this.build_tween;
			this.build_bar.width = 0;
		}
	}

	orbHit(key: number) {
		const orb_indicator = this.orb_indicators[key];
		if (orb_indicator) {
			const tint = orb_tints[orb_indicator.state];
			orb_indicator.fillColor = hit_tints[orb_indicator.state];
			orb_indicator.setScale(2);
			this.time.addEvent({
				delay: 200,
				callback: () => {
					orb_indicator.fillColor = tint;
					orb_indicator.setScale(1);
				}
			});
		}
	}

	switchOrb(key: number, team: string) {
		const orb_indicator = this.orb_indicators[key];
		orb_indicator.setState(team);
		if (orb_indicator) {
			orb_indicator.fillColor = orb_tints[team];
		}
	}
}
