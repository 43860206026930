import React from 'react';
import FragLog from './FragLog';
import WinScreen from './WinScreen';
import GameLog from './GameLog';

const HUD = () => {
	return (
		<div className="game-hud">
			<WinScreen />
			<FragLog />
			<GameLog />
		</div>
	);
};

export default HUD;
