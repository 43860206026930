import React, { useEffect, useState } from 'react';
import '../styles/win_screen.css';
import PlayerSprite from '../playerSprite';

// TODO: Move to server code, make return statement for Player method
type PlayerStats = {
	kills: number;
	deaths: number;
	damage_dealt: number;
	captures: number;
	defences: number;
	points: number;
	healing?: number;
	invulns?: number;
};

type WinScreenStats = {
	team: string;
	points: { red: number; blue: number };
	red: (PlayerStats & PlayerSprite)[];
	blue: (PlayerStats & PlayerSprite)[];
};

const WinScreen = () => {
	const [stats, setStats] = useState(null as WinScreenStats | null);
	useEffect(() => {
		const handleRoundWon = (e: any) => {
			setStats(e.detail);
		};

		const handleRoundStarted = () => {
			setStats(null);
		};

		document.addEventListener('roundwon', handleRoundWon);
		document.addEventListener('roundstart', handleRoundStarted);
		return () => {
			document.removeEventListener('roundwon', handleRoundWon);
			document.removeEventListener('roundstart', handleRoundStarted);
		};
	}, []);

	if (!stats) {
		return <div />;
	}

	const { team, red, blue, points } = stats;
	const me = red.concat(blue).find(p => p.isMe);
	return (
		<div className="win-screen">
			<h2>
				<span className={team.toLowerCase()}>{team.toUpperCase()}</span> Won!
			</h2>
			<div className="win-screen-body mb-4">
				<div className="w-100 d-flex justify-content-between">
					<div className="d-flex flex-column red">
						<div className="red">
							<strong>Red - {points.red}</strong>
						</div>
						{red.map((r, i) => (
							<div key={i}>{r.name}</div>
						))}
					</div>
					<div className="d-flex flex-column text-right">
						<div>
							<strong>Points</strong>
						</div>
						{red.map((r, i) => (
							<div key={i}>{r.points}</div>
						))}
					</div>
					<div className="d-flex flex-column blue">
						<strong>Blue - {points.blue}</strong>
						{blue.map((b, i) => (
							<div key={i}>{b.name}</div>
						))}
					</div>
					<div className="d-flex flex-column text-right">
						<div>
							<strong>Points</strong>
						</div>
						{blue.map((r, i) => (
							<div key={i}>{r.points}</div>
						))}
					</div>
				</div>
			</div>
			{me && (
				<div className="win-screen-footer">
					<strong>Your Stats</strong>
					<div className="d-flex justify-content-between">
						<div className="d-flex flex-column">
							<div>Kills:</div>
							<div>Deaths:</div>
							<div>Damage dealt:</div>
							<div>Healing</div>
						</div>
						<div className="d-flex flex-column px-4 text-right">
							<div>{me.kills}</div>
							<div>{me.deaths}</div>
							<div>{me.damage_dealt}</div>
							<div>{me.healing ?? 0}</div>
						</div>
						<div className="d-flex flex-column px-4">
							<div>Captures:</div>
							<div>Defences:</div>
							<div>Invulns:</div>
							<div>Points:</div>
						</div>
						<div className="d-flex flex-column text-right">
							<div>{me.captures}</div>
							<div>{me.defences}</div>
							<div>{me.invulns ?? 0}</div>
							<div>{me.points}</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default WinScreen;
