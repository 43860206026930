import { Room } from 'colyseus.js';
import { MESSAGES } from '../server/constants';

interface IKeyHandler {
	isDown: boolean;
	isUp: boolean;
	press?: () => void;
	release?: () => void;
	downHandler: (e: KeyboardEvent) => void;
	upHandler: (e: KeyboardEvent) => void;
}

function addKeyHandler(keyCode: string) {
	const key: IKeyHandler = {
		downHandler: event => {
			if (event.code === keyCode) {
				if (key.isUp && key.press) {
					key.press();
				}
				key.isDown = true;
				key.isUp = false;
			}
			event.preventDefault();
		},
		isDown: false,
		isUp: true,
		upHandler: event => {
			if (event.code === keyCode) {
				if (key.isDown && key.release) {
					key.release();
				}
				key.isDown = false;
				key.isUp = true;
			}
			event.preventDefault();
		}
	};

	window.addEventListener('keydown', key.downHandler, false);
	window.addEventListener('keyup', key.upHandler, false);
	return key;
}

export function setupMovement(room: Room) {
	// const left = addKeyHandler('ArrowLeft');
	// const up = addKeyHandler('ArrowUp');
	// const right = addKeyHandler('ArrowRight');
	// const down = addKeyHandler('ArrowDown');
	const left = addKeyHandler('KeyA');
	const up = addKeyHandler('KeyW');
	const right = addKeyHandler('KeyD');
	const down = addKeyHandler('KeyS');

	const a_button = addKeyHandler('Period');
	const b_button = addKeyHandler('Slash');
	const c_button = addKeyHandler('Comma');

	// Left.
	left.press = () => {
		if (!right.isDown) {
			room.send(MESSAGES.move, { vx: -1 });
		} else {
			room.send(MESSAGES.move, { vx: 0 });
		}
	};

	left.release = () => {
		if (!right.isDown) {
			room.send(MESSAGES.move, { vx: 0 });
		} else {
			room.send(MESSAGES.move, { vx: 1 });
		}
	};

	// Up.
	up.press = () => {
		if (!down.isDown) {
			room.send(MESSAGES.move, { vy: -1 });
		} else {
			room.send(MESSAGES.move, { vy: 0 });
		}
	};

	up.release = () => {
		if (!down.isDown) {
			room.send(MESSAGES.move, { vy: 0 });
		} else {
			room.send(MESSAGES.move, { vy: 1 });
		}
	};

	// Right.
	right.press = () => {
		if (!left.isDown) {
			room.send(MESSAGES.move, { vx: 1 });
		} else {
			room.send(MESSAGES.move, { vx: 0 });
		}
	};

	right.release = () => {
		if (!left.isDown) {
			room.send(MESSAGES.move, { vx: 0 });
		} else {
			room.send(MESSAGES.move, { vx: -1 });
		}
	};

	// Down.
	down.press = () => {
		if (!up.isDown) {
			room.send(MESSAGES.move, { vy: 1 });
		} else {
			room.send(MESSAGES.move, { vy: 0 });
		}
	};

	down.release = () => {
		if (!up.isDown) {
			room.send(MESSAGES.move, { vy: 0 });
		} else {
			room.send(MESSAGES.move, { vy: -1 });
		}
	};

	a_button.press = () => {
		room.send(MESSAGES.attack, { primary: true });
	};

	a_button.release = () => {
		room.send(MESSAGES.attack, { primary: false });
	};

	b_button.press = () => {
		room.send(MESSAGES.attack, { secondary: true });
	};

	b_button.release = () => {
		room.send(MESSAGES.attack, { secondary: false });
	};

	c_button.press = () => {
		room.send(MESSAGES.attack, { tertiary: true });
	};

	c_button.release = () => {
		room.send(MESSAGES.attack, { tertiary: false });
	};

	const cleanupListeners = () => {
		for (const k of [left, right, up, down, a_button, b_button, c_button]) {
			window.removeEventListener('keydown', k.downHandler, false);
			window.removeEventListener('keyup', k.upHandler, false);
		}
	};

	return cleanupListeners;
}
