import React, { useState, useEffect } from 'react';
import Phaser from 'phaser';
import { Room } from 'colyseus.js';

import HUD from './components/HUD';
import TitleScreen from './components/TitleScreen';
import AdminPanel from './components/AdminPanel';

import ClassSelectScene from './scenes/classSelectScene';
import BattleScene, { width, height } from './scenes/battleScene';
import HUDScene from './scenes/hudScene';
import { loadSprites, setupAnimations } from './animations';
import { connect } from './client';
import { ClientState } from './clientState';
import { setupMovement } from './controls';
import { MESSAGES } from '../server/constants';

class PreloadScene extends Phaser.Scene {
	constructor() {
		super({ key: 'PreloadScene' });
	}

	preload() {
		loadSprites(this);
		this.load.on('complete', (this.game as any).onComplete);
	}

	async create() {
		setupAnimations(this);
		this.scene.start('BattleScene');
	}
}

let game: Phaser.Game;
let client_state: ClientState;
let room: Room;

const App = () => {
	const [connected, setConnected] = useState(false);
	const [is_admin, setIsAdmin] = useState(false);
	const [loading_game, setLoadingGame] = useState(true);
	const [loading_remote, setLoadingRemote] = useState(false);
	const [error, setError] = useState('');

	useEffect(() => {
		game = new Phaser.Game({
			type: Phaser.AUTO,
			width,
			height,
			parent: 'game',
			scene: [PreloadScene, BattleScene, HUDScene, ClassSelectScene],
			callbacks: {
				postBoot: game => {
					// Center horizontally and vertically. Needs to be set here or else Phaser's
					// mouse handlers are off, and the margin needs to be set explicitly or the
					// game "slides down" when loading
					game.scale.autoCenter = 1;
					game.scale.updateCenter();
					game.scale.canvas.style.marginTop = '6px';
				}
			}
		});

		(game as any).onComplete = () => {
			client_state = new ClientState(
				game.scene.getScene('BattleScene') as BattleScene,
				game.scene.getScene('HUDScene') as HUDScene
			);
			game.registry.set('client_state', client_state);
			setLoadingGame(false);
		};
	}, []);

	const handleError = (code: number, message?: string) => {
		if (code === 4215) {
			setError('The room password was incorrect.');
		} else {
			setError(message || `Received error code ${code} from the server`);
		}
	};

	const handleJoin = (data: { name: string; room_password: string }) => {
		setLoadingRemote(true);
		return connect(client_state, data)
			.then(client => {
				game.registry.set('client', client);
				game.scene.run('ClassSelectScene');
				setConnected(true);
				setLoadingRemote(false);
				const unsetMovement = setupMovement(client.room);

				client.room.onLeave(code => {
					console.log('Disconnected with code', code);
					setConnected(false);
					setIsAdmin(false);
					setError(`Disconnected from server. (${code})`);
					(game.scene.getScene('ClassSelectScene') as any).reset();
					game.scene.stop('HUDScene');
					unsetMovement();
					client_state.reset();
				});
				client.room.onMessage('admin', () => {
					setIsAdmin(true);
				});
				client.room.onError(handleError);
				room = client.room;
			})
			.catch(err => {
				setLoadingRemote(false);
				handleError(err.code, err.message);
			});
	};

	return (
		<div className="game-app">
			{connected ? (
				<>
					<HUD />
					{is_admin && (
						<AdminPanel
							startGame={() => {
								room?.send(MESSAGES.start_game);
							}}
							resetGame={() => {
								room?.send(MESSAGES.reset_game);
							}}
						/>
					)}
				</>
			) : (
				<TitleScreen
					loading_game={loading_game}
					loading_remote={loading_remote}
					error={error}
					handleSubmit={handleJoin}
				/>
			)}
		</div>
	);
};

export default App;
