const message_keys = [
	'move',
	'attack',
	'join',
	'start_game',
	'reset_game'
] as const;
type MessageKey = typeof message_keys[number];

export const MESSAGES = message_keys.reduce(
	(acc, val) => ({
		...acc,
		[val]: val
	}),
	{} as Record<MessageKey, string>
);

export const directions = ['up', 'down', 'left', 'right'] as const;
export type Direction = typeof directions[number];

export const classNames = ['sord', 'bord', 'word', 'fjord'] as const;
export type ClassName = typeof classNames[number];

export const teams = ['red', 'blue'] as const;
export type Team = typeof teams[number];

type BroadcastMessage =
	| { key: 'frag'; src: string; dest: string; weapon?: WeaponType }
	| { key: 'capture'; by: string[] }
	| { key: 'defence'; by: string }
	| {
			key: 'won';
			team: string;
			points: { red: number; blue: number };
			blue: any;
			red: any;
	  }
	| { key: 'round_start' }
	| { key: 'medallion_picked'; by: string; medallion_type: MedallionType }
	| { key: 'joined'; id: string };
export type BroadcastFn = (data: BroadcastMessage) => any;

const animations = [
	'up',
	'down',
	'right',
	'left',
	'move_up',
	'move_down',
	'move_right',
	'move_left',
	'attack_primary_up',
	'attack_primary_down',
	'attack_primary_left',
	'attack_primary_right',
	'attack_secondary_up',
	'attack_secondary_down',
	'attack_secondary_left',
	'attack_secondary_right'
] as const;
export type AnimationKey = typeof animations[number];

export const ANIMS = animations.reduce(
	(acc, val) => ({ ...acc, [val]: val }),
	{} as Record<AnimationKey, string>
);

export enum GameState {
	Lobby,
	Match
}

export enum WeaponType {
	Mine,
	FireShield,
	Reflect
}

export enum MedallionType {
	None,
	Earth,
	Water,
	Fire,
	Air
}

export const MedallionCycle = [
	// MedallionType.Air
	// MedallionType.Earth,
	// MedallionType.Water,
	MedallionType.Fire
];

export const MedallionKeys: Record<MedallionType, string> = {
	[MedallionType.None]: '',
	[MedallionType.Earth]: 'earth',
	[MedallionType.Water]: 'water',
	[MedallionType.Fire]: 'fire',
	[MedallionType.Air]: 'air'
};

export const MedallionTextures = Object.entries(MedallionKeys).reduce(
	(acc, [key, val]) => ({
		...acc,
		[key]: key === String(MedallionType.None) ? val : `${val}_medallion`
	}),
	{} as Record<MedallionType, string>
);

export const invuln_time = 7500;
