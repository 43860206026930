import React from 'react';
import '../styles/game_log.css';

type GameMessage = {
	id: number;
	body: string;
	opacity: number;
};

type GameLogState = {
	messages: GameMessage[];
	timeouts: Record<number, number>;
};

class GameLog extends React.Component<{}, GameLogState> {
	log = React.createRef<HTMLDivElement>();
	constructor(props: {}) {
		super(props);

		this.state = {
			messages: [],
			timeouts: {}
		};
	}

	componentDidMount() {
		document.addEventListener('gamemessage', this.handleMessage);
	}

	componentWillUnmount() {
		document.removeEventListener('gamemessage', this.handleMessage);
		Object.values(this.state.timeouts).forEach(timeout =>
			clearInterval(timeout)
		);
	}

	handleMessage = (e: any) => {
		const message: GameMessage = { ...e.detail, opacity: 1 };
		if (process.env.NODE_ENV === 'development') {
			console.log(message);
		}
		this.setState(state => ({ messages: [...state.messages, message] }));
		if (this.log.current) {
			this.log.current.scrollTop = this.log.current.scrollHeight;
		}

		const timeout = window.setTimeout(() => {
			this.setState(state => {
				const new_timeouts = { ...state.timeouts };
				delete new_timeouts[timeout];
				return {
					messages: state.messages.map(m =>
						m.id === message.id ? { ...m, opacity: 0 } : m
					),
					timeouts: new_timeouts
				};
			});
		}, 2000);

		this.setState(state => ({
			timeouts: { ...state.timeouts, [timeout]: timeout }
		}));
	};

	render() {
		return (
			<div className="game-log">
				<div className="game-log-content" ref={this.log}>
					{this.state.messages.map(m => (
						<div
							className="game-log-message"
							key={m.id}
							style={{ opacity: m.opacity }}
						>
							{m.body}
						</div>
					))}
				</div>
			</div>
		);
	}
}

export default GameLog;
