import { Scene } from 'phaser';

import sord_blue_back from './images/Sord/sord-blue-back.png';
import sord_blue_front from './images/Sord/sord-blue-front.png';
import sord_blue_left from './images/Sord/sord-blue-left.png';
import sord_blue_right from './images/Sord/sord-blue-right.png';
import sord_blue_attack_front from './images/Sord/sord-blue-attack-front.png';
import sord_blue_attack_back from './images/Sord/sord-blue-attack-back.png';
import sord_blue_attack_left from './images/Sord/sord-blue-attack-left.png';
import sord_blue_attack_right from './images/Sord/sord-blue-attack-right.png';
import sord_blue_suspended from './images/Sord/sord-blue-suspended.png';
import sord_red_back from './images/Sord/sord-red-back.png';
import sord_red_front from './images/Sord/sord-red-front.png';
import sord_red_left from './images/Sord/sord-red-left.png';
import sord_red_right from './images/Sord/sord-red-right.png';
import sord_red_attack_front from './images/Sord/sord-red-attack-front.png';
import sord_red_attack_back from './images/Sord/sord-red-attack-back.png';
import sord_red_attack_left from './images/Sord/sord-red-attack-left.png';
import sord_red_attack_right from './images/Sord/sord-red-attack-right.png';
import sord_red_suspended from './images/Sord/sord-red-suspended.png';

import bord_blue_back from './images/Bord/bord-blue-back.png';
import bord_blue_front from './images/Bord/bord-blue-front.png';
import bord_blue_left from './images/Bord/bord-blue-left.png';
import bord_blue_right from './images/Bord/bord-blue-right.png';
import bord_blue_attack_up from './images/Bord/bord-blue-attack-up.png';
import bord_blue_attack_front from './images/Bord/bord-blue-attack-front.png';
import bord_blue_attack_left from './images/Bord/bord-blue-attack-left.png';
import bord_blue_attack_right from './images/Bord/bord-blue-attack-right.png';
import bord_blue_suspended from './images/Bord/bord-blue-suspended.png';
import bord_red_back from './images/Bord/bord-red-back.png';
import bord_red_front from './images/Bord/bord-red-front.png';
import bord_red_left from './images/Bord/bord-red-left.png';
import bord_red_right from './images/Bord/bord-red-right.png';
import bord_red_attack_up from './images/Bord/bord-red-attack-back.png';
import bord_red_attack_front from './images/Bord/bord-red-attack-front.png';
import bord_red_attack_left from './images/Bord/bord-red-attack-left.png';
import bord_red_attack_right from './images/Bord/bord-red-attack-right.png';
import bord_red_suspended from './images/Bord/bord-red-suspended.png';

import fjord_blue_front from './images/Fjord/fjord-blue-front.png';
import fjord_blue_back from './images/Fjord/fjord-blue-back.png';
import fjord_blue_left from './images/Fjord/fjord-blue-left.png';
import fjord_blue_right from './images/Fjord/fjord-blue-right.png';
import fjord_blue_attack_front from './images/Fjord/fjord-blue-attack-front.png';
import fjord_blue_attack_back from './images/Fjord/fjord-blue-attack-back.png';
import fjord_blue_attack_left from './images/Fjord/fjord-blue-attack-left.png';
import fjord_blue_attack_right from './images/Fjord/fjord-blue-attack-right.png';
import fjord_blue_suspended from './images/Fjord/fjord-blue-suspended.png';
import fjord_red_front from './images/Fjord/fjord-red-front.png';
import fjord_red_back from './images/Fjord/fjord-red-back.png';
import fjord_red_left from './images/Fjord/fjord-red-left.png';
import fjord_red_right from './images/Fjord/fjord-red-right.png';
import fjord_red_attack_front from './images/Fjord/fjord-red-attack-front.png';
import fjord_red_attack_back from './images/Fjord/fjord-red-attack-back.png';
import fjord_red_attack_left from './images/Fjord/fjord-red-attack-left.png';
import fjord_red_attack_right from './images/Fjord/fjord-red-attack-right.png';
import fjord_red_suspended from './images/Fjord/fjord-red-suspended.png';

import word_blue_front from './images/Word/word-blue-front.png';
import word_blue_back from './images/Word/word-blue-back.png';
import word_blue_left from './images/Word/word-blue-left.png';
import word_blue_right from './images/Word/word-blue-right.png';
import word_blue_attack_front from './images/Word/word-blue-attack-front.png';
import word_blue_attack_back from './images/Word/word-blue-attack-back.png';
import word_blue_attack_left from './images/Word/word-blue-attack-left.png';
import word_blue_attack_right from './images/Word/word-blue-attack-right.png';
import word_blue_suspended from './images/Word/word-blue-suspended.png';
import word_red_front from './images/Word/word-red-front.png';
import word_red_back from './images/Word/word-red-back.png';
import word_red_left from './images/Word/word-red-left.png';
import word_red_right from './images/Word/word-red-right.png';
import word_red_attack_front from './images/Word/word-red-attack-front.png';
import word_red_attack_back from './images/Word/word-red-attack-back.png';
import word_red_attack_left from './images/Word/word-red-attack-left.png';
import word_red_attack_right from './images/Word/word-red-attack-right.png';
import word_red_suspended from './images/Word/word-red-suspended.png';

import arrow from './images/arrow.png';
import frost_arrow from './images/frost-arrow.png';
import shadow from './images/shadow.png';
import heal_rune from './images/Runes/heal-rune.png';
import water_rune from './images/Runes/water-rune.png';
import mine from './images/mine.png';
import explosion from './images/explosion.png';
import fire from './images/fire.png';
import water from './images/water.png';
import air from './images/air.png';
import portal_blue from './images/Word/portal-blue.png';
import portal_red from './images/Word/portal-red.png';
import boulder from './images/boulder.png';
import orb_blue from './images/orb-blue.png';
import orb_red from './images/orb-red.png';
import orb_white from './images/orb-white.png';
import fire_medallion from './images/medallions/fire-medallion.png';
import water_medallion from './images/medallions/water-medallion.png';
import earth_medallion from './images/medallions/earth-medallion.png';
import air_medallion from './images/medallions/air-medallion.png';
import { teams, classNames } from '../server/constants';

export const loadSprites = (scene: Scene) => {
	[
		{ key: 'sord_blue_walk_down', file: sord_blue_front },
		{ key: 'sord_blue_walk_up', file: sord_blue_back, width: 48 },
		{ key: 'sord_blue_walk_left', file: sord_blue_left, width: 48 },
		{ key: 'sord_blue_walk_right', file: sord_blue_right, width: 48 },
		{ key: 'sord_blue_swing_down', file: sord_blue_attack_front },
		{ key: 'sord_blue_swing_up', file: sord_blue_attack_back },
		{ key: 'sord_blue_swing_right', file: sord_blue_attack_right },
		{ key: 'sord_blue_swing_left', file: sord_blue_attack_left },
		{ key: 'sord_blue_midair', file: sord_blue_suspended },
		{ key: 'sord_red_walk_down', file: sord_red_front },
		{ key: 'sord_red_walk_up', file: sord_red_back },
		{ key: 'sord_red_walk_left', file: sord_red_left },
		{ key: 'sord_red_walk_right', file: sord_red_right },
		{ key: 'sord_red_swing_down', file: sord_red_attack_front },
		{ key: 'sord_red_swing_up', file: sord_red_attack_back },
		{ key: 'sord_red_swing_right', file: sord_red_attack_right },
		{ key: 'sord_red_swing_left', file: sord_red_attack_left },
		{ key: 'sord_red_midair', file: sord_red_suspended },

		{ key: 'bord_blue_walk_down', file: bord_blue_front },
		{ key: 'bord_blue_walk_up', file: bord_blue_back, width: 48 },
		{ key: 'bord_blue_walk_left', file: bord_blue_left, width: 48 },
		{ key: 'bord_blue_walk_right', file: bord_blue_right },
		{
			key: 'bord_blue_swing_down',
			file: bord_blue_attack_front
		},
		{ key: 'bord_blue_swing_up', file: bord_blue_attack_up },
		{ key: 'bord_blue_swing_right', file: bord_blue_attack_right },
		{ key: 'bord_blue_swing_left', file: bord_blue_attack_left },
		{ key: 'bord_blue_midair', file: bord_blue_suspended },
		{ key: 'bord_red_walk_down', file: bord_red_front },
		{ key: 'bord_red_walk_up', file: bord_red_back },
		{ key: 'bord_red_walk_left', file: bord_red_left },
		{ key: 'bord_red_walk_right', file: bord_red_right },
		{
			key: 'bord_red_swing_down',
			file: bord_red_attack_front
		},
		{ key: 'bord_red_swing_up', file: bord_red_attack_up },
		{ key: 'bord_red_swing_right', file: bord_red_attack_right },
		{ key: 'bord_red_swing_left', file: bord_red_attack_left },
		{ key: 'bord_red_midair', file: bord_red_suspended },

		{ key: 'fjord_blue_walk_down', file: fjord_blue_front },
		{ key: 'fjord_blue_walk_up', file: fjord_blue_back },
		{ key: 'fjord_blue_walk_left', file: fjord_blue_left },
		{ key: 'fjord_blue_walk_right', file: fjord_blue_right },
		{ key: 'fjord_blue_draw_down', file: fjord_blue_attack_front },
		{ key: 'fjord_blue_draw_up', file: fjord_blue_attack_back },
		{ key: 'fjord_blue_draw_left', file: fjord_blue_attack_left },
		{ key: 'fjord_blue_draw_right', file: fjord_blue_attack_right },
		{ key: 'fjord_blue_midair', file: fjord_blue_suspended },
		{ key: 'fjord_red_walk_down', file: fjord_red_front },
		{ key: 'fjord_red_walk_up', file: fjord_red_back },
		{ key: 'fjord_red_walk_left', file: fjord_red_left },
		{ key: 'fjord_red_walk_right', file: fjord_red_right },
		{ key: 'fjord_red_draw_down', file: fjord_red_attack_front },
		{ key: 'fjord_red_draw_up', file: fjord_red_attack_back },
		{ key: 'fjord_red_draw_left', file: fjord_red_attack_left },
		{ key: 'fjord_red_draw_right', file: fjord_red_attack_right },
		{ key: 'fjord_red_midair', file: fjord_red_suspended },

		{ key: 'word_blue_walk_down', file: word_blue_front },
		{ key: 'word_blue_walk_up', file: word_blue_back },
		{ key: 'word_blue_walk_left', file: word_blue_left },
		{ key: 'word_blue_walk_right', file: word_blue_right },
		{ key: 'word_blue_spell_down', file: word_blue_attack_front },
		{ key: 'word_blue_spell_up', file: word_blue_attack_back },
		{ key: 'word_blue_spell_left', file: word_blue_attack_left },
		{ key: 'word_blue_spell_right', file: word_blue_attack_right },
		{ key: 'word_blue_midair', file: word_blue_suspended },
		{ key: 'word_red_walk_down', file: word_red_front },
		{ key: 'word_red_walk_up', file: word_red_back },
		{ key: 'word_red_walk_left', file: word_red_left },
		{ key: 'word_red_walk_right', file: word_red_right },
		{ key: 'word_red_spell_down', file: word_red_attack_front },
		{ key: 'word_red_spell_up', file: word_red_attack_back },
		{ key: 'word_red_spell_left', file: word_red_attack_left },
		{ key: 'word_red_spell_right', file: word_red_attack_right },
		{ key: 'word_red_midair', file: word_red_suspended },

		{ key: 'shadow', file: shadow }
	].forEach(({ key, file }) => {
		scene.load.spritesheet(key, file, {
			frameWidth: 48,
			frameHeight: 48
		});
	});
	scene.load.spritesheet('arrow', arrow, { frameWidth: 19, frameHeight: 19 });
	scene.load.spritesheet('frost_arrow', frost_arrow, {
		frameWidth: 19,
		frameHeight: 19
	});
	scene.load.spritesheet('explosion', explosion, {
		frameWidth: 29,
		frameHeight: 29
	});
	scene.load.spritesheet('fire', fire, { frameWidth: 17, frameHeight: 17 });
	scene.load.spritesheet('water', water, { frameWidth: 17, frameHeight: 17 });
	scene.load.spritesheet('air', air, { frameWidth: 17, frameHeight: 17 });
	scene.load.spritesheet('portal_blue', portal_blue, {
		frameWidth: 17,
		frameHeight: 17
	});
	scene.load.spritesheet('portal_red', portal_red, {
		frameWidth: 17,
		frameHeight: 17
	});
	scene.load.image('mine', mine);
	scene.load.image('boulder', boulder);
	scene.load.image('heal_rune', heal_rune);
	scene.load.image('water_rune', water_rune);
	scene.load.image('orb_blue', orb_blue);
	scene.load.image('orb_red', orb_red);
	scene.load.image('orb_white', orb_white);
	scene.load.image('fire_medallion', fire_medallion);
	scene.load.image('water_medallion', water_medallion);
	scene.load.image('earth_medallion', earth_medallion);
	scene.load.image('air_medallion', air_medallion);
};

export const attack_duration = 300;

export const setupAnimations = (scene: Scene) => {
	teams.forEach(team => {
		classNames.forEach(className => {
			[
				{
					key: `${className}_${team}_move_down`,
					spritesheet: `${className}_${team}_walk_down`,
					end: 6
				},
				{
					key: `${className}_${team}_move_up`,
					spritesheet: `${className}_${team}_walk_up`,
					end: 7
				},
				{
					key: `${className}_${team}_move_left`,
					spritesheet: `${className}_${team}_walk_left`,
					end: 7
				},
				{
					key: `${className}_${team}_move_right`,
					spritesheet: `${className}_${team}_walk_right`,
					end: 7
				},
				{
					key: `${className}_${team}_suspended`,
					spritesheet: `${className}_${team}_midair`,
					end: 4
				}
			].forEach(({ key, spritesheet, end }) => {
				scene.anims.create({
					key,
					frames: scene.anims.generateFrameNumbers(spritesheet, { end }),
					frameRate: 10
				});
			});
		});

		[
			{
				key: `bord_${team}_charging_down`,
				spritesheet: `bord_${team}_walk_down`,
				end: 7
			},
			{
				key: `bord_${team}_charging_up`,
				spritesheet: `bord_${team}_walk_up`,
				end: 8
			},
			{
				key: `bord_${team}_charging_left`,
				spritesheet: `bord_${team}_walk_left`,
				end: 8
			},
			{
				key: `bord_${team}_charging_right`,
				spritesheet: `bord_${team}_walk_right`,
				end: 8
			}
		].forEach(({ key, spritesheet, end }) => {
			scene.anims.create({
				key,
				frames: scene.anims.generateFrameNumbers(spritesheet, { end }),
				frameRate: 20
			});
		});

		[
			{
				key: `sord_${team}_down`,
				spritesheet: `sord_${team}_walk_down`,
				frame: 7
			},
			{ key: `sord_${team}_up`, spritesheet: `sord_${team}_walk_up`, frame: 8 },
			{
				key: `sord_${team}_left`,
				spritesheet: `sord_${team}_walk_left`,
				frame: 8
			},
			{
				key: `sord_${team}_right`,
				spritesheet: `sord_${team}_walk_right`,
				frame: 8
			},

			{
				key: `bord_${team}_down`,
				spritesheet: `bord_${team}_walk_down`,
				frame: 2
			},
			{ key: `bord_${team}_up`, spritesheet: `bord_${team}_walk_up`, frame: 4 },
			{
				key: `bord_${team}_left`,
				spritesheet: `bord_${team}_walk_left`,
				frame: 2
			},
			{
				key: `bord_${team}_right`,
				spritesheet: `bord_${team}_walk_right`,
				frame: 2
			},

			{
				key: `fjord_${team}_down`,
				spritesheet: `fjord_${team}_walk_down`,
				frame: 7
			},
			{
				key: `fjord_${team}_up`,
				spritesheet: `fjord_${team}_walk_up`,
				frame: 8
			},
			{
				key: `fjord_${team}_left`,
				spritesheet: `fjord_${team}_walk_left`,
				frame: 8
			},
			{
				key: `fjord_${team}_right`,
				spritesheet: `fjord_${team}_walk_right`,
				frame: 8
			},

			{
				key: `word_${team}_down`,
				spritesheet: `word_${team}_walk_down`,
				frame: 7
			},
			{ key: `word_${team}_up`, spritesheet: `word_${team}_walk_up`, frame: 8 },
			{
				key: `word_${team}_left`,
				spritesheet: `word_${team}_walk_left`,
				frame: 8
			},
			{
				key: `word_${team}_right`,
				spritesheet: `word_${team}_walk_right`,
				frame: 8
			}
		].forEach(({ key, spritesheet, frame }) => {
			scene.anims.create({
				key,
				frames: [{ key: spritesheet, frame }],
				frameRate: 20
			});
		});

		['sord', 'bord'].forEach(className => {
			[
				{
					key: `${className}_${team}_attack_secondary_down`,
					spritesheet: `${className}_${team}_swing_down`,
					end: 5
				},
				{
					key: `${className}_${team}_attack_secondary_up`,
					spritesheet: `${className}_${team}_swing_up`,
					end: 4
				},
				{
					key: `${className}_${team}_attack_secondary_right`,
					spritesheet: `${className}_${team}_swing_right`,
					end: 4
				},
				{
					key: `${className}_${team}_attack_secondary_left`,
					spritesheet: `${className}_${team}_swing_left`,
					end: 4
				}
			].forEach(({ key, spritesheet, end }) => {
				scene.anims.create({
					key,
					frames: scene.anims.generateFrameNumbers(spritesheet, { end }),
					duration: attack_duration
				});
			});
		});

		[
			{
				key: `fjord_${team}_attack_secondary_down`,
				spritesheet: `fjord_${team}_draw_down`,
				end: 2
			},
			{
				key: `fjord_${team}_attack_secondary_up`,
				spritesheet: `fjord_${team}_draw_up`,
				end: 2
			},
			{
				key: `fjord_${team}_attack_secondary_right`,
				spritesheet: `fjord_${team}_draw_right`,
				end: 2
			},
			{
				key: `fjord_${team}_attack_secondary_left`,
				spritesheet: `fjord_${team}_draw_left`,
				end: 2
			},
			{
				key: `word_${team}_attack_secondary_down`,
				spritesheet: `word_${team}_spell_down`,
				end: 2
			},
			{
				key: `word_${team}_attack_secondary_up`,
				spritesheet: `word_${team}_spell_up`,
				end: 2
			},
			{
				key: `word_${team}_attack_secondary_left`,
				spritesheet: `word_${team}_spell_left`,
				end: 2
			},
			{
				key: `word_${team}_attack_secondary_right`,
				spritesheet: `word_${team}_spell_right`,
				end: 2
			}
		].forEach(({ key, spritesheet, end }) => {
			scene.anims.create({
				key,
				frames: scene.anims.generateFrameNumbers(spritesheet, { end }),
				duration: attack_duration
			});
		});

		scene.anims.create({
			key: `fjord_${team}_charging_down`,
			frames: [{ key: `fjord_${team}_draw_down`, frame: 2 }],
			frameRate: 20
		});
		scene.anims.create({
			key: `fjord_${team}_charging_up`,
			frames: [{ key: `fjord_${team}_draw_up`, frame: 2 }],
			frameRate: 20
		});
		scene.anims.create({
			key: `fjord_${team}_charging_right`,
			frames: [{ key: `fjord_${team}_draw_right`, frame: 2 }],
			frameRate: 20
		});
		scene.anims.create({
			key: `fjord_${team}_charging_left`,
			frames: [{ key: `fjord_${team}_draw_left`, frame: 2 }],
			frameRate: 20
		});

		scene.anims.create({
			key: `word_${team}_charging_down`,
			frames: scene.anims.generateFrameNumbers(`word_${team}_spell_down`, {
				start: 2,
				end: 5
			}),
			frameRate: 14
		});
		scene.anims.create({
			key: `word_${team}_charging_up`,
			frames: scene.anims.generateFrameNumbers(`word_${team}_spell_up`, {
				start: 2,
				end: 5
			}),
			frameRate: 14
		});
		scene.anims.create({
			key: `word_${team}_charging_left`,
			frames: scene.anims.generateFrameNumbers(`word_${team}_spell_left`, {
				start: 2,
				end: 5
			}),
			frameRate: 14
		});
		scene.anims.create({
			key: `word_${team}_charging_right`,
			frames: scene.anims.generateFrameNumbers(`word_${team}_spell_right`, {
				start: 2,
				end: 5
			}),
			frameRate: 14
		});
	});

	scene.anims.create({
		key: 'shadow',
		frames: [{ key: 'shadow', frame: 0 }],
		frameRate: 20
	});

	scene.anims.create({
		key: 'mine',
		frames: [{ key: 'mine', frame: 0 }],
		frameRate: 10
	});

	scene.anims.create({
		key: 'explosion',
		frames: scene.anims.generateFrameNumbers('explosion', { start: 1, end: 6 }),
		frameRate: 14
	});

	scene.anims.create({
		key: 'fire',
		frames: scene.anims.generateFrameNumbers('fire', { start: 0, end: 9 }),
		frameRate: 10,
		repeat: -1
	});

	scene.anims.create({
		key: 'water',
		frames: scene.anims.generateFrameNumbers('water', { start: 0, end: 5 }),
		frameRate: 10,
		repeat: -1
	});

	scene.anims.create({
		key: 'air',
		frames: scene.anims.generateFrameNumbers('air', { start: 0, end: 5 }),
		frameRate: 10,
		repeat: -1
	});

	['portal_red', 'portal_blue'].forEach(key => {
		scene.anims.create({
			key,
			frames: scene.anims.generateFrameNumbers(key, {
				start: 0,
				end: 3
			}),
			frameRate: 10,
			repeat: -1
		});
	});
};
