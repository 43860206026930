import Player from '../server/models/player';
import { Direction, ClassName, Team, MedallionType } from '../server/constants';
import Vector from '../server/models/vector';

export default class PlayerSprite {
	id: string;
	hp: number;
	max_hp: number;
	build: number;
	charge: number;
	max_build: number;
	stamina: number;
	max_stamina: number;
	team: Team;
	name: string;
	direction: Direction;
	position: Vector;
	className: ClassName;
	last_attack_primary: number | null = null;
	last_attack_secondary: number | null = null;
	last_release = 0;
	is_attacking_primary = false;
	is_attacking_secondary = false;
	attackDirection: Direction | null = null;
	isMe: boolean;
	is_moving: boolean;
	is_holding = false;
	is_held = false;
	is_cloaked: boolean | null = null;
	is_hidden = false;
	charging = false;
	show_damage = false;
	show_healing = false;
	invulnerable = false;
	frozen = false;
	died: boolean | null = null;
	sprite: Phaser.GameObjects.Sprite;
	tween?: Phaser.Tweens.Tween;
	medallion: MedallionType;
	children: {
		name_text?: Phaser.GameObjects.Text;
		health_outline?: Phaser.GameObjects.Rectangle;
		health_bar?: Phaser.GameObjects.Rectangle;
		build_outline?: Phaser.GameObjects.Rectangle;
		build_bar?: Phaser.GameObjects.Rectangle;
		stamina_outline?: Phaser.GameObjects.Rectangle;
		stamina_bar?: Phaser.GameObjects.Rectangle;
		invulnerable_rune?: Phaser.GameObjects.Image;
		active_medallion?: Phaser.GameObjects.Sprite;
		vacuum?: Phaser.GameObjects.Arc;
	} = {};
	scene: Phaser.Scene;

	constructor(
		id: string,
		player: Player,
		sprite: Phaser.GameObjects.Sprite,
		scene: Phaser.Scene,
		isMe: boolean
	) {
		// Sync initial state.
		this.id = id;
		this.hp = player.hp;
		this.max_hp = player.max_hp;
		this.charge = player.charge;
		this.build = (player as any).build ?? 0;
		this.max_build = (player as any).max_build ?? 0;
		this.stamina = (player as any).stamina ?? 0;
		this.max_stamina = (player as any).max_stamina ?? 0;
		this.sprite = sprite;
		this.team = player.team;
		this.name = player.name;
		this.frozen = player.frozen;
		this.position = new Vector(sprite.x, sprite.y);
		this.direction = player.direction;
		this.className = player.className;
		this.is_moving = player.is_moving;
		this.isMe = isMe;
		this.scene = scene;
		this.medallion = player.medallion;
		this.setMedallion(player.medallion);
	}

	setLastAttackPrimary(value: number) {
		if (this.className !== 'fjord') {
			this.last_attack_primary = value;
		}
	}

	setLastAttackSecondary(value: number) {
		// Messages can arrive out of order; make sure that we did not a receive a
		// more recent "release" message
		if (!this.charging && value > this.last_release) {
			// Bord doesn't have a "charge start" animation, only a charging one
			if (this.className !== 'bord') {
				this.last_attack_secondary = value;
			}
			if (this.className !== 'sord') {
				this.charging = true;
			}
		}
	}

	setCharge(value: number) {
		this.charge = value;
	}

	setChargingSecondary(value: boolean) {
		if (this.className !== 'sord') {
			this.charging = value;
		}
	}

	setDisplayPosition(x: number, y: number) {
		this.sprite.setPosition(x, y);

		Object.values(this.children).forEach(child => {
			child?.setPosition(x + (child.state as number), y);
		});
	}

	setVacuuming(vacuuming: boolean) {
		if (vacuuming) {
			this.children.vacuum = this.scene.add.circle(
				this.position.x,
				this.position.y,
				100,
				0xcccccc
			);
			this.children.vacuum.alpha = 0.3;
		} else if (this.children.vacuum) {
			this.children.vacuum.destroy();
			delete this.children.vacuum;
		}
	}

	setMedallion(medallion: MedallionType) {
		switch (medallion) {
			case MedallionType.None:
				if (this.children.active_medallion) {
					this.children.active_medallion.destroy();
					delete this.children.active_medallion;
				}
				this.sprite.alpha = 1;
				break;
			case MedallionType.Fire: {
				const fire = this.scene.add.sprite(
					this.position.x,
					this.position.y,
					'fire'
				);
				fire.setDisplaySize(30, 30);
				fire.play('fire');
				this.children.active_medallion = fire;
				break;
			}
			case MedallionType.Water: {
				const water = this.scene.add.sprite(
					this.position.x,
					this.position.y,
					'water'
				);
				water.setDisplaySize(30, 30);
				water.play('water');
				water.alpha = 0.6;
				this.children.active_medallion = water;
				if (this.className === 'sord') {
					this.sprite.alpha = water.alpha;
				}
				break;
			}
			case MedallionType.Air: {
				const air = this.scene.add.sprite(
					this.position.x,
					this.position.y,
					'air'
				);
				air.setDisplaySize(30, 30);
				air.play('air');
				air.alpha = 0.8;
				this.children.active_medallion = air;
				break;
			}
			default:
				throw new Error(`Medallion not yet implemented: ${medallion}`);
		}
		this.medallion = medallion;
	}

	showDeath() {
		this.sprite.alpha = 0;
		this.died = true;
		this.charging = false;
		Object.values(this.children).forEach(child => {
			if (child) {
				child.alpha = 0;
			}
		});
	}

	showRespawn() {
		// this.position.setXY(50, 50);
		// this.sprite.setPosition(50, 56);
		this.sprite.alpha = 1;
		Object.values(this.children).forEach(child => {
			if (child) {
				// TODO: Reset to original alpha
				child.alpha = 1;
			}
		});
	}

	showAttack() {
		if (this.className !== 'sord') {
			return;
		}
	}

	showDamage() {
		this.show_damage = true;
		this.scene.time.addEvent({
			delay: 100,
			callback: () => {
				this.show_damage = false;
			}
		});
	}

	showHealing() {
		this.show_healing = true;
		this.scene.time.addEvent({
			delay: 200,
			callback: () => {
				this.show_healing = false;
			}
		});
	}
}
