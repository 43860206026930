export default class ArrowSprite {
	sprite: Phaser.GameObjects.Sprite;
	server_x: number;
	server_y: number;

	constructor(
		sprite: Phaser.GameObjects.Sprite,
		server_x: number,
		server_y: number
	) {
		this.sprite = sprite;
		this.server_x = server_x;
		this.server_y = server_y;

		this.sprite.x = this.server_x;
		this.sprite.y = this.server_y;
	}
}
