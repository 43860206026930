import Phaser from 'phaser';
import tilesheet from '../images/pipoya.png';
import wallsheet from '../images/wall.png';
import map from '../images/fieldoftheaccord.png';
import { ClientState } from '../clientState';
import PlayerSprite from '../playerSprite';
import { attack_duration } from '../animations';
import Player from '../../server/models/player';

export const width = 800;
export const height = 608;
const lerp_rate = 0.06;
const lerp_limit = 30;
const arrow_lerp_rate = 0.01;

const lerp = (start: number, end: number, t: number) =>
	start * (1 - t) + end * t;

// Uncomment to disable lerping
// const lerp = (start: number, end: number, t: number) => end;

export default class BattleScene extends Phaser.Scene {
	state!: ClientState;
	poly?: Phaser.GameObjects.Polygon;

	constructor() {
		super({ key: 'BattleScene' });
	}

	preload() {
		this.load.image('levelmap', map);
		this.load.image('tiles', tilesheet);
		this.load.image('walltiles', wallsheet);
		this.load.tilemapTiledJSON('map', 'maps/mapthing2.json');
	}

	async create() {
		this.state = this.registry.get('client_state');
		this.add.image(width / 2, height / 2, 'levelmap');
		this.cameras.main.setBounds(0, 0, width, height);
		const selectObj = this.input.keyboard.addKey('H');
		selectObj.on('up', () => {
			if (this.state.me) {
				const cs_scene = 'ClassSelectScene';
				if (this.scene.isActive(cs_scene)) {
					// Dismiss the scene
					this.scene.stop(cs_scene);
				} else {
					(this.scene.get(cs_scene) as any).reset();
					this.scene.run(cs_scene);
				}
			}
		});
	}

	addPlayer(x: number, y: number, isMe = false) {
		const sprite = this.add.sprite(x, y, '');
		// this.poly = this.add.polygon(0, 0, points, 0xfff);
		// circle = this.add.circle(x, y, 8, 0xfff);
		if (isMe) {
			this.cameras.main.setZoom(2);
			this.cameras.main.startFollow(sprite);
		}
		return sprite;
	}

	setupHUD(player: Player, state: ClientState) {
		this.scene.run('HUDScene', { player, state });
	}

	movePlayer(player: PlayerSprite, x: number, y: number) {
		// player.sprite.setPosition(x, y + 6);
		player.position.setXY(x, y - 6);
		// player.position.setXY(x, y + 6);
		// circle.setPosition(x, y);
		// poly.setPosition(x - poly_origin.x, y - poly_origin.y);
		player.sprite.setDepth(player.is_held ? y + 10 : y);
	}

	update(_time: number, delta: number) {
		for (const arrow of Object.values(this.state.projectiles)) {
			arrow.sprite.x = lerp(
				arrow.sprite.x,
				arrow.server_x,
				Math.exp(-arrow_lerp_rate * delta)
			);
			arrow.sprite.y = lerp(
				arrow.sprite.y,
				arrow.server_y,
				Math.exp(-arrow_lerp_rate * delta)
			);
		}

		for (const fireball of Object.values(this.state.fireballs)) {
			fireball.sprite.x = lerp(
				fireball.sprite.x,
				fireball.server_x,
				Math.exp(-arrow_lerp_rate * delta)
			);
			fireball.sprite.y = lerp(
				fireball.sprite.y,
				fireball.server_y,
				Math.exp(-arrow_lerp_rate * delta)
			);
		}

		for (const player of Object.values(this.state.players)) {
			player.setDisplayPosition(
				Math.abs(player.sprite.x - player.position.x) < lerp_limit
					? lerp(
							player.sprite.x,
							player.position.x,
							Math.exp(-lerp_rate * delta)
					  )
					: player.position.x,
				Math.abs(player.sprite.y - player.position.y) < lerp_limit
					? lerp(
							player.sprite.y,
							player.position.y,
							Math.exp(-lerp_rate * delta)
					  )
					: player.position.y
			);
			if (player.last_attack_primary) {
				player.is_attacking_primary = true;
				player.last_attack_primary = null;
				player.attackDirection = player.direction;
				this.time.addEvent({
					delay: attack_duration,
					callback: () => {
						player.is_attacking_primary = false;
						player.attackDirection = null;
					}
				});
			}
			if (player.last_attack_secondary) {
				player.is_attacking_secondary = true;
				player.last_attack_secondary = null;
				player.attackDirection = player.direction;
				this.time.addEvent({
					delay: attack_duration,
					callback: () => {
						player.is_attacking_secondary = false;
						player.attackDirection = null;
					}
				});
			}

			if (player.is_cloaked !== null) {
				const is_cloaked = player.is_cloaked;
				if (player.hp > 0) {
					if (player.tween) {
						player.tween.stop();
						delete player.tween;
					}
					player.tween = this.add.tween({
						targets: [
							player.sprite,
							...(player.children.active_medallion
								? [player.children.active_medallion]
								: [])
						],
						ease: 'Sine.easeInOut',
						duration: 600,
						delay: 0,
						alpha: {
							getStart: () => {
								return Number(is_cloaked);
							},
							getEnd: () => {
								return Number(!is_cloaked);
							}
						},
						onStart: () => {
							if (player.team === this.state.me?.team && !is_cloaked) {
								player.is_hidden = is_cloaked;
							}
						},
						onComplete: () => {
							if (player.team === this.state.me?.team) {
								player.sprite.alpha = 1;
								if (is_cloaked) {
									player.is_hidden = is_cloaked;
								}
							}
							delete player.tween;
						}
					});
					player.is_cloaked = null;
					return;
				}
			}

			// Tints
			if (player.show_damage) {
				player.sprite.setTint(0xeb2727);
			} else if (player.show_healing) {
				player.sprite.setTint(0x98e2c6);
			} else if (player.frozen) {
				player.sprite.setTint(0xaaaaee);
			} else if (player.invulnerable) {
				player.sprite.setTint(player.team === 'red' ? 0xcc0000 : 0x000fff);
			} else {
				player.sprite.clearTint();
			}

			if (player.is_held) {
				player.sprite.play(
					`${player.className}_${player.team}_suspended`,
					true
				);
			} else if (player.is_hidden) {
				player.sprite.play(`shadow`);
			} else {
				player.sprite.play(
					`${player.className}_${player.team}_${
						player.is_attacking_primary || player.is_attacking_secondary
							? 'attack_secondary_'
							: player.charging
							? 'charging_'
							: player.is_moving
							? 'move_'
							: ''
					}${player.attackDirection ?? player.direction}`,
					true
				);
			}
		}
	}
}
