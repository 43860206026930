import Player from '../server/models/player';
import Spell from '../server/models/spells/spell';
import Mine from '../server/models/mine';
import Fireball from '../server/models/spells/fireball';
import PlayerSprite from './playerSprite';
import ArrowSprite from './arrowSprite';
import {
	BoulderSprite,
	FireballSprite,
	MineSprite,
	PortalSprite
} from './sprites';
import BattleScene from './scenes/battleScene';
import HUDScene from './scenes/hudScene';
import {
	Team,
	GameState,
	MedallionType,
	MedallionTextures,
	invuln_time
} from '../server/constants';
import Orb from '../server/models/orb';
import Medallion from '../server/models/medallion';
import Projectile from '../server/models/projectile';
import Portal from '../server/models/spells/portal';
import Boulder from '../server/models/boulder';

const health_bar_width = 20;
const teammate_attr_opacity = 0.7;

export class ClientState {
	players: Record<string, PlayerSprite> = {};
	projectiles: Record<string, ArrowSprite> = {};
	spells: Record<string, Phaser.GameObjects.Image> = {};
	mines: Record<string, MineSprite> = {};
	portals: Record<string, PortalSprite> = {};
	fireballs: Record<string, FireballSprite> = {};
	boulders: Record<string, BoulderSprite> = {};
	orbs: Record<number, Phaser.GameObjects.Image> = {};
	medallions: Record<number, Phaser.GameObjects.Image> = {};
	scene: BattleScene;
	hud: HUDScene;
	me?: PlayerSprite;
	victory_points = {
		red: 0,
		blue: 0
	};
	game_state = GameState.Lobby;

	constructor(scene: BattleScene, hud: HUDScene) {
		this.scene = scene;
		this.hud = hud;
	}

	reset() {
		Object.values(this.orbs).forEach(orb => {
			orb.destroy();
		});
		Object.values(this.medallions).forEach(medallion => {
			medallion.destroy();
		});
		Object.keys(this.players).forEach(player_id => {
			this.removePlayer(player_id);
		});
		this.victory_points.red = this.victory_points.blue = 0;
		delete this.me;
	}

	setGameState(game_state: GameState) {
		this.game_state = game_state;
		this.hud.setGameState(game_state);
	}

	addPlayer(id: string, player: Player, isMe: boolean) {
		const sprite = this.scene.addPlayer(
			player.position.x,
			player.position.y,
			isMe
		);

		this.players[id] = new PlayerSprite(id, player, sprite, this.scene, isMe);
		this.scene.movePlayer(
			this.players[id],
			player.position.x,
			player.position.y
		);

		if (this.me?.team === player.team) {
			this.addTeammateAttributes(this.players[id]);
		}
		if (isMe) {
			this.me = this.players[id];
			// TODO: This is an unsustainble way to manage sprites that need to change
			// when the current player changes teams (in case it becomes more than
			// just mines).
			Object.values(this.mines).forEach(mine => {
				mine.sprite.setAlpha(this.me!.team === mine.team ? 1 : 0.3);
			});
			this.scene.setupHUD(player, this);
			Object.values(this.players).forEach(p => {
				this.removeTeammateAttributes(p);
				if (this.me!.team === p.team) {
					this.addTeammateAttributes(p);
				}
			});
		}

		// This only happens when changing classes/teams while a player is dead
		if (player.hp === 0) {
			this.players[id].showDeath();
		}
	}

	addTeammateAttributes(player: PlayerSprite) {
		if (player.team === this.me?.team) {
			const depth = 1001;

			const name_text = this.scene.add.text(
				player.position.x,
				player.position.y,
				player.name,
				{
					font: 'bold 8px Courier',
					fill: '#ffffff',
					resolution: 2
				}
			);
			name_text.setDepth(depth);
			name_text.setOrigin(0.5, 3);
			player.children.name_text = name_text;
			if (player.isMe) {
				name_text.setOrigin(0.5, 2.5);
				if (player.className === 'fjord') {
					const stamina_outline = this.scene.add.rectangle(
						player.position.x,
						player.position.y,
						health_bar_width + 1,
						3
					);
					stamina_outline.isStroked = true;
					stamina_outline.setDepth(depth);
					stamina_outline.setOrigin(0, 4.5);
					stamina_outline.setState(-stamina_outline.width / 2);
					player.children.stamina_outline = stamina_outline;

					const stamina_bar = this.scene.add.rectangle(
						player.position.x,
						player.position.y,
						health_bar_width * (player.stamina / player.max_stamina),
						2,
						0xffffff
					);
					stamina_bar.setDepth(depth);
					stamina_bar.setState(-stamina_bar.width / 2);
					stamina_bar.setOrigin(0, 6.5);
					stamina_bar.setAlpha(teammate_attr_opacity);
					player.children.stamina_bar = stamina_bar;
				}
				return;
			}

			// Health and build bars use the Game Object state as a width offset.
			const health_outline = this.scene.add.rectangle(
				player.position.x,
				player.position.y,
				health_bar_width + 1,
				4
			);
			health_outline.isStroked = true;
			health_outline.setOrigin(0, 4.5);
			health_outline.setState(-health_outline.width / 2);
			health_outline.setDepth(depth);
			player.children.health_outline = health_outline;

			// TODO: Organize colours in code
			const health_bar = this.scene.add.rectangle(
				player.position.x,
				player.position.y,
				health_bar_width,
				3,
				0x78ff76
			);
			health_bar.setOrigin(0, 5.8);
			health_bar.setState(-health_bar.width / 2);
			health_bar.setDepth(depth);
			health_bar.setAlpha(teammate_attr_opacity);
			player.children.health_bar = health_bar;
			this.updateHP(player.id, player.hp);

			if (player.className === 'word') {
				const build_outline = this.scene.add.rectangle(
					player.position.x,
					player.position.y,
					health_bar_width + 1,
					3
				);
				build_outline.isStroked = true;
				build_outline.setOrigin(0, 4.7);
				build_outline.setDepth(depth);
				build_outline.setState(-build_outline.width / 2);

				const build_bar = this.scene.add.rectangle(
					player.position.x,
					player.position.y,
					0,
					2,
					0xfff
				);
				build_bar.setOrigin(0, 6.7);
				build_bar.setState(-health_bar_width / 2);
				build_bar.setAlpha(teammate_attr_opacity);
				build_bar.setDepth(depth);

				player.children.build_outline = build_outline;
				player.children.build_bar = build_bar;
				this.updateBuild(player.id, player.build);
			}
		}
	}

	removeTeammateAttributes(player: PlayerSprite) {
		player.children.name_text?.destroy();
		player.children.stamina_bar?.destroy();
		player.children.stamina_outline?.destroy();
		player.children.health_outline?.destroy();
		player.children.health_bar?.destroy();
		player.children.build_outline?.destroy();
		player.children.build_bar?.destroy();
	}

	updateHP(id: string, hp: number) {
		const player = this.players[id];
		player.hp = hp;
		if (player.children.health_bar) {
			player.children.health_bar.width =
				health_bar_width * (hp / player.max_hp);
		}
		if (hp === 0 && player.children.build_bar) {
			player.tween?.stop();
			delete player.tween;
			player.children.build_bar.width = 0;
		}
		if (player.isMe) {
			this.hud.updateHP(hp);
		}
	}

	updateBuild(id: string, build: number) {
		const player = this.players[id];
		// TODO: Reduce duplicated code with HUD
		if (player.children.build_bar) {
			if (player.build === player.max_build && build === 0 && player.hp > 0) {
				player.tween = this.scene.add.tween({
					targets: [player.children.build_bar],
					duration: invuln_time,
					delay: 0,
					onComplete: () => {
						delete player.tween;
					},
					width: {
						getStart: () => health_bar_width,
						getEnd: () => 0
					}
				});
			} else {
				player.children.build_bar.width =
					health_bar_width * (build / player.max_build);
			}
		}
		if (player.isMe) {
			this.hud.setBuild(build);
		}
		player.build = build;
	}

	updateStamina(id: string, stamina: number) {
		const player = this.players[id];
		if (player.children.stamina_bar) {
			player.children.stamina_bar.width =
				health_bar_width * (stamina / player.max_stamina);
		}
		player.stamina = stamina;
	}

	editClass(id: string, className: string) {
		this.players[id].className = className as any;
	}

	addOrb(orb: Orb, key: number) {
		const orb_sprite = this.scene.add.image(
			orb.position.x,
			orb.position.y,
			`orb_${orb.team || 'white'}`
		);
		orb_sprite.setState(orb.team);
		orb_sprite.setDepth(orb.position.y);
		this.orbs[key] = orb_sprite;
	}

	updateOrbHP(key: number, hp: number) {
		if (hp) {
			const orb: Phaser.GameObjects.Image = this.orbs[key];
			orb.setTint(0xcccccc);
			this.scene.time.addEvent({
				delay: 200,
				callback: () => {
					orb.clearTint();
				}
			});
			this.hud.orbHit(key);
		}
	}

	switchOrbTeam(key: number, team: Team) {
		const orb: Phaser.GameObjects.Image = this.orbs[key];
		orb.setTexture(`orb_${team || 'white'}`);
		this.hud.switchOrb(key, team);
	}

	addMedallion(medallion: Medallion, key: number) {
		const medallion_sprite = this.scene.add.image(
			medallion.position.x,
			medallion.position.y,
			MedallionTextures[medallion.medallion_type] || 'fire_medallion'
		);
		medallion_sprite.alpha = Number(
			medallion.medallion_type !== MedallionType.None
		);
		this.medallions[key] = medallion_sprite;
	}

	updateMedallionType(key: number, medallion_type: MedallionType) {
		if (this.medallions[key]) {
			this.medallions[key].alpha = Number(
				medallion_type !== MedallionType.None
			);
			if (MedallionTextures[medallion_type]) {
				this.medallions[key].setTexture(MedallionTextures[medallion_type]);
			}
		}
	}

	getProjectileFrameFromVelocity(proj: Projectile) {
		const velocity = proj.velocity;
		if (velocity.x > 0) {
			if (velocity.y > 0) {
				return 7;
			} else if (velocity.y < 0) {
				return 5;
			} else {
				return 6;
			}
		} else if (velocity.x < 0) {
			if (velocity.y > 0) {
				return 1;
			} else if (velocity.y < 0) {
				return 3;
			} else {
				return 2;
			}
		} else {
			if (velocity.y > 0) {
				return 0;
			} else {
				return 4;
			}
		}
	}

	addProjectile(id: string, proj: Projectile) {
		const p = this.scene.add.sprite(
			proj.position.x,
			proj.position.y,
			proj.frost ? 'frost_arrow' : 'arrow',
			this.getProjectileFrameFromVelocity(proj)
		);
		this.projectiles[id] = new ArrowSprite(p, proj.position.x, proj.position.y);
	}

	updateProjectile(id: string, proj: Projectile) {
		if (
			this.projectiles[id].sprite.frame.name !==
			String(this.getProjectileFrameFromVelocity(proj))
		) {
			this.projectiles[id].sprite.setFrame(
				this.getProjectileFrameFromVelocity(proj)
			);
		}
		this.projectiles[id].server_x = proj.position.x;
		this.projectiles[id].server_y = proj.position.y;
	}

	removeProjectile(id: string) {
		if (this.projectiles[id]) {
			this.projectiles[id].sprite.destroy();
			delete this.projectiles[id];
		}
	}

	addSpell(id: string, spell: Spell, team: Team) {
		const rune = this.scene.add.image(
			spell.position.x,
			spell.position.y,
			spell.boosted ? 'water_rune' : 'heal_rune'
		);
		const diameter = spell.radius * 2 + 10;
		rune.setDisplaySize(diameter, diameter);
		rune.setAlpha(0.6);
		rune.setTintFill(
			team === 'red' ? 0xf08b8b : spell.boosted ? 0x2c67db : 0x9fb7f5
		);
		this.spells[id] = rune;
	}

	removeSpell(id: string) {
		if (this.spells[id]) {
			this.spells[id].destroy();
			delete this.spells[id];
		}
	}

	addMine(id: string, mine: Mine, team: Team) {
		this.mines[id] = {
			sprite: this.scene.add.sprite(mine.position.x, mine.position.y, 'mine'),
			radius: mine.radius,
			team
		};
		if (this.me && this.me.team !== team) {
			this.mines[id].sprite.setAlpha(0.3);
		}
	}

	removeMine(id: string) {
		this.mines[id]?.sprite.destroy();
		delete this.mines[id];
	}

	explodeMine(id: string) {
		if (this.mines[id]) {
			const mine_sprite = this.mines[id].sprite;
			const diameter = this.mines[id].radius * 2;

			delete this.mines[id];

			mine_sprite.setDisplaySize(diameter, diameter);
			mine_sprite.setAlpha(1);
			mine_sprite.on('animationcomplete', () => {
				mine_sprite.destroy();
			});
			mine_sprite.play('explosion');
		}
	}

	addPortal(id: string, portal: Portal, team: Team) {
		const p = this.scene.add.sprite(
			portal.position.x,
			portal.position.y,
			`portal_${team}`
		);
		p.play(`portal_${team}`);
		this.portals[id] = { sprite: p, hp: portal.hp };
	}

	updatePortal(id: string, portal: Portal) {
		if (this.portals[id]) {
			if (portal.hp < this.portals[id].hp) {
				this.portals[id].sprite.setTint(0xcccccc);
				this.scene.time.addEvent({
					delay: 200,
					callback: () => this.portals[id]?.sprite.clearTint()
				});
			}
		}
	}

	removePortal(id: string) {
		if (this.portals[id]) {
			this.portals[id].sprite.destroy();
			delete this.portals[id];
		}
	}

	addBoulder(id: string, boulder: Boulder, _team: Team) {
		const b = this.scene.add.sprite(
			boulder.position.x,
			boulder.position.y,
			'boulder'
		);
		const diameter = 20;
		b.setDisplaySize(diameter, diameter);
		this.boulders[id] = { sprite: b as any, hp: boulder.hp };
	}

	updateBoulder(id: string, boulder: Boulder) {
		if (this.boulders[id]) {
			if (boulder.hp < this.boulders[id].hp) {
				this.boulders[id].sprite.setTint(0xcccccc);
				this.scene.time.addEvent({
					delay: 200,
					callback: () => this.boulders[id]?.sprite.clearTint()
				});
			}
		}
	}

	removeBoulder(id: string) {
		if (this.boulders[id]) {
			this.boulders[id].sprite.destroy();
			delete this.boulders[id];
		}
	}

	addFireball(id: string, fireball: Fireball) {
		const fb = {
			server_x: fireball.position.x,
			server_y: fireball.position.y,
			sprite: this.scene.add.sprite(
				fireball.position.x,
				fireball.position.y,
				'explosion',
				0
			)
		};
		const diameter = fireball.radius * 2;
		fb.sprite.setDisplaySize(diameter, diameter);
		this.fireballs[id] = fb;
	}

	removeFireball(id: string) {
		if (this.fireballs[id]) {
			const fb_sprite = this.fireballs[id].sprite;
			delete this.fireballs[id];

			fb_sprite.on('animationcomplete', () => {
				fb_sprite.destroy();
			});
			fb_sprite.play('explosion');
		}
	}

	updateFireball(id: string, fireball: Fireball) {
		if (this.fireballs[id]) {
			this.fireballs[id].server_x = fireball.position.x;
			this.fireballs[id].server_y = fireball.position.y;
		}
	}

	removePlayer(id: string) {
		const player = this.players[id];
		if (player.className === 'fjord') {
			Object.keys(this.projectiles).forEach(k => {
				if (k.startsWith(id)) {
					this.removeProjectile(k);
				}
			});
			Object.keys(this.mines).forEach(k => {
				if (k.startsWith(id)) {
					this.removeMine(k);
				}
			});
		} else if (player.className === 'word') {
			Object.keys(this.spells).forEach(k => {
				if (k.startsWith(id)) {
					this.removeSpell(k);
				}
			});
			Object.keys(this.fireballs).forEach(k => {
				if (k.startsWith(id)) {
					this.removeFireball(k);
				}
			});
			Object.keys(this.portals).forEach(k => {
				if (k.startsWith(id)) {
					this.removePortal(k);
				}
			});
		} else if (player.className === 'bord') {
			Object.keys(this.boulders).forEach(k => {
				if (k.startsWith(id)) {
					this.removeBoulder(k);
				}
			});
		}
		Object.values(player.children).forEach(child => {
			child?.destroy();
		});
		player.sprite.destroy();
		if (this.me?.id === id) {
			delete this.me;
		}
		delete this.players[id];
	}

	setVictoryPoints(team: string, points: number) {
		if (team === 'red') {
			this.victory_points.red = points;
			this.hud.victory_points?.red.setText(String(points));
		} else {
			this.victory_points.blue = points;
			this.hud.victory_points?.blue.setText(String(points));
		}
	}

	setInvulnerable(player: PlayerSprite, invulnerable: boolean) {
		player.invulnerable = invulnerable;
	}

	setInvulnerableRune(player: PlayerSprite, invulnerable: boolean) {
		if (invulnerable) {
			player.children.invulnerable_rune = this.scene.add.image(
				player.sprite.x,
				player.sprite.y,
				'heal_rune'
			);
			const diameter = 110;
			player.children.invulnerable_rune.setDisplaySize(diameter, diameter);
			player.children.invulnerable_rune.setTintFill(
				player.team === 'red' ? 0x990033 : 0x0099ff
			);
		} else {
			if (player.children.invulnerable_rune) {
				player.children.invulnerable_rune.destroy();
				delete player.children.invulnerable_rune;
			}
		}
	}
}
