import React, { useState, FormEvent, useEffect } from 'react';
import title_img from '../images/title.png';

type TitleScreenProps = {
	loading_remote: boolean;
	loading_game: boolean;
	error: string;
	handleSubmit: (data: { name: string; room_password: string }) => void;
};

const TitleScreen: React.FC<TitleScreenProps> = props => {
	const [name, setName] = useState('');
	const [room_password, setRoomPassword] = useState('');
	const [display_loading_game, setDisplayLoadingGame] = useState(false);
	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();
		props.handleSubmit({ name, room_password });
	};

	useEffect(() => {
		const timeout = setTimeout(() => setDisplayLoadingGame(true), 700);
		return () => clearTimeout(timeout);
	}, []);

	return (
		<div className="title-screen">
			<div className="title-screen-modal">
				<img className="title-logo" src={title_img} alt="" />
				<form className="title-screen-form" onSubmit={handleSubmit}>
					<div>
						<label>
							Name:
							<br />
							<input
								type="text"
								name="name"
								value={name}
								onChange={e => setName(e.target.value)}
							/>
						</label>
					</div>
					<div>
						<label>
							Room password:
							<br />
							<input
								type="password"
								name="room-password"
								autoComplete="room-password"
								value={room_password}
								onChange={e => setRoomPassword(e.target.value)}
							/>
						</label>
					</div>
					<button
						className="mt-1"
						disabled={
							!(name && room_password) ||
							props.loading_remote ||
							props.loading_game
						}
					>
						{props.loading_game && display_loading_game
							? 'Setting up game...'
							: props.loading_remote
							? 'Joining...'
							: 'Join'}
					</button>
					{props.error && <div className="form-error mt-1">{props.error}</div>}
				</form>
			</div>
		</div>
	);
};

export default TitleScreen;
