import React from 'react';
import PlayerSprite from '../playerSprite';
import { iconForClass, iconForWeapon } from './icons';
import {
	MedallionType,
	MedallionKeys,
	WeaponType
} from '../../server/constants';

import '../styles/frag_log.css';

type FragMessage =
	| {
			key: 'frag';
			src: PlayerSprite;
			dest: PlayerSprite;
			id: string;
			weapon?: WeaponType;
	  }
	| { key: 'capture'; players: PlayerSprite[]; id: string }
	| { key: 'defence'; player: PlayerSprite; id: string }
	| {
			key: 'medallion_picked';
			player: PlayerSprite;
			medallion_type: MedallionType;
			id: string;
	  };

const titleCaseWord = (word: string) => word[0].toUpperCase() + word.substr(1);
const vowels = 'aeiou';

const FragMessage: React.FC<{ message: FragMessage }> = ({ message }) => {
	if (message.key === 'frag') {
		const is_me = message.src.isMe || message.dest.isMe;
		const icon_props = { classes: 'frag-icon' };
		const icon = message.weapon
			? iconForWeapon(message.weapon, icon_props)
			: iconForClass(message.src.className, icon_props);
		return (
			<div className={`game-frag-message ${is_me ? 'frag-message-me' : ''}`}>
				<span className={message.src.team}>{message.src.name}</span>
				{icon}
				<span className={message.dest.team}>{message.dest.name}</span>
			</div>
		);
	} else if (message.key === 'defence') {
		const is_me = message.player.isMe;
		return (
			<div className={`game-frag-message ${is_me ? 'frag-message-me' : ''}`}>
				<span className={message.player.team}>{message.player.name}</span>{' '}
				defended an Örb!
			</div>
		);
	} else if (message.key === 'medallion_picked') {
		const is_me = message.player.isMe;
		const key = MedallionKeys[message.medallion_type];
		return (
			<div className={`game-frag-message ${is_me ? 'frag-message-me' : ''}`}>
				<span className={message.player.team}>{message.player.name}</span>{' '}
				picked up a{vowels.includes(key[0]) ? 'n' : ''} {titleCaseWord(key)}{' '}
				Medallion!
			</div>
		);
	} else {
		const is_me = message.players.some(p => p.isMe);
		return (
			<div className={`game-frag-message ${is_me ? 'frag-message-me' : ''}`}>
				<span className={message.players[0].team}>
					{message.players.map(p => p.name).join(' + ')}
				</span>{' '}
				captured an Örb!
			</div>
		);
	}
};

const max_messages_length = 5;

type FragLogState = {
	messages: FragMessage[];
	timeouts: Record<number, number>;
};

class FragLog extends React.Component<{}, FragLogState> {
	constructor(props: {}) {
		super(props);
		this.state = { messages: [], timeouts: {} };
	}

	componentDidMount() {
		document.addEventListener('frag', this.handleMessage);
		document.addEventListener('capture', this.handleMessage);
		document.addEventListener('defence', this.handleMessage);
		document.addEventListener('medallion_picked', this.handleMessage);
	}

	componentWillUnmount() {
		document.removeEventListener('frag', this.handleMessage);
		document.removeEventListener('capture', this.handleMessage);
		document.removeEventListener('defence', this.handleMessage);
		document.removeEventListener('medallion_picked', this.handleMessage);
		Object.values(this.state.timeouts).forEach(timeout =>
			clearInterval(timeout)
		);
	}

	handleMessage = (e: any) => {
		const message: FragMessage = e.detail;
		this.setState(({ messages }) => ({
			messages: [
				...(messages.length > max_messages_length
					? messages.slice(messages.length - max_messages_length)
					: messages),
				message
			]
		}));
		const timeout = window.setTimeout(() => {
			this.setState(({ messages, timeouts }) => {
				const new_timeouts = { ...timeouts };
				delete new_timeouts[timeout];
				return {
					messages: messages.filter(m => m.id !== message.id),
					timeouts: new_timeouts
				};
			});
		}, 10000);

		this.setState(({ timeouts }) => ({
			timeouts: { ...timeouts, [timeout]: timeout }
		}));
	};

	render() {
		return (
			<div className="game-frag-log">
				{this.state.messages.map(m => (
					<FragMessage key={m.id} message={m} />
				))}
			</div>
		);
	}
}
export default FragLog;
