import Phaser from 'phaser';
// import { ClassName } from '../../server/models/player';

type MenuSprite = {
	sprite: Phaser.GameObjects.Sprite;
	selected_indicator?: Phaser.GameObjects.Arc;
};

const DESCRIPTIONS = {
	'': 'Please select a team and a class.',
	sord: 'Sword\n\nUse your blade to cut enemies down.',
	bord: 'Shield\n\nHurtle towards your foes at breakneck speeds.',
	fjord:
		'Bow\n\nShoot your enemies full of arrows, then disappear with your cloak.',
	word: 'Hat\n\nBolster your team with magical spells.'
};

export default class ClassSelectScene extends Phaser.Scene {
	sprites!: Record<string, MenuSprite>;
	red_team_button!: Phaser.GameObjects.Arc;
	blue_team_button!: Phaser.GameObjects.Arc;
	description!: Phaser.GameObjects.Text;
	state = {
		team: '',
		selected_class: '',
		hovered_class: ''
	};

	constructor() {
		super({ key: 'ClassSelectScene' });
	}

	create() {
		const graphics = this.add.graphics();
		graphics.fillStyle(0x000000, 0.5);
		graphics.fillRect(100, 100, 600, 400);

		graphics.fillStyle(0xead38c, 1);
		graphics.fillRect(120, 300, 560, 180);
		this.description = this.add.text(124, 304, DESCRIPTIONS[''], {
			font: '24px Courier',
			fill: '#000000',
			wordWrap: { width: 560 }
		});
		this.red_team_button = this.add.circle(330, 160, 35, 0xeb4747);
		this.blue_team_button = this.add.circle(470, 160, 35, 0x2d9cdb);
		this.red_team_button.setInteractive();
		this.red_team_button.on('pointerdown', () => {
			this.state.team = 'red';
		});
		this.blue_team_button.setInteractive();
		this.blue_team_button.on('pointerdown', () => {
			this.state.team = 'blue';
		});

		this.sprites = {
			sord: { sprite: this.add.sprite(235, 246, '') },
			bord: { sprite: this.add.sprite(345, 246, '') },
			fjord: { sprite: this.add.sprite(455, 246, '') },
			word: { sprite: this.add.sprite(565, 246, '') }
		};
		Object.entries(this.sprites).forEach(([class_name, s]) => {
			s.selected_indicator = this.add.circle(
				s.sprite.x,
				s.sprite.y,
				40,
				0xffffff,
				0.6
			);
			s.sprite.setDepth(1);
			s.sprite.setScale(2.5);
			s.sprite.setInteractive();
			s.sprite.on('pointerover', () => {
				this.state.hovered_class = class_name;
			});
			s.sprite.on('pointerout', () => {
				this.state.hovered_class = '';
			});
			s.sprite.on('pointerdown', () => {
				this.state.selected_class = class_name;
			});
		});
	}

	joinBattle() {
		this.registry.get('client').joinBattle({
			className: this.state.selected_class,
			team: this.state.team
		});
		this.scene.stop();
	}

	reset() {
		this.state = {
			team: '',
			selected_class: '',
			hovered_class: ''
		};
	}

	update() {
		Object.entries(this.sprites).forEach(([class_name, s]) => {
			const anim_key = `${class_name}_${this.state.team || 'red'}_${
				this.state.hovered_class === class_name ||
				this.state.selected_class === class_name
					? 'move_'
					: ''
			}down`;
			if (this.state.selected_class === class_name) {
				s.selected_indicator!.alpha = 1;
			} else {
				s.selected_indicator!.alpha = 0;
			}
			// This is due to the update function called before the animations are
			// loaded
			if (this.anims.exists(anim_key)) {
				s.sprite.play(anim_key, true);
			}
		});
		if (this.state.team === 'red') {
			this.red_team_button.setRadius(45);
			this.blue_team_button.setRadius(35);
		} else if (this.state.team === 'blue') {
			this.red_team_button.setRadius(35);
			this.blue_team_button.setRadius(45);
		}

		this.description.setText(
			DESCRIPTIONS[this.state.hovered_class || this.state.selected_class]
		);

		if (
			this.state.selected_class &&
			this.state.team &&
			this.registry.get('client')
		) {
			this.joinBattle();
		}
	}
}
