import * as Colyseus from 'colyseus.js';
import Bord from '../server/models/bord';
import Fjord from '../server/models/fjord';
import Word from '../server/models/word';
import Player from '../server/models/player';
import { ClientState } from './clientState';
import Notifications from './notifications';
import PlayerSprite from './playerSprite';
import { MESSAGES, ClassName, Team } from '../server/constants';
import Orb from '../server/models/orb';
import Medallion from '../server/models/medallion';

const url = `${window.location.protocol === 'https:' ? 'wss://' : 'ws://'}${
	process.env.NODE_ENV === 'development'
		? `${window.location.hostname}:5000`
		: window.location.host
}`;

const client = new Colyseus.Client(url);

export type BattleClient = {
	room: Colyseus.Room;
	joinBattle: (data: {
		nickname: string;
		className: ClassName;
		team: Team;
	}) => void;
};

export async function connect(state: ClientState, data: object) {
	const room = await client.joinOrCreate<any>('battle', data);
	const loaded = Date.now();

	const updateHP = (id: string, p: PlayerSprite, hp: number) => {
		if (p.hp > hp) {
			p.showDamage();
		} else if (p.hp < hp && hp < p.max_hp) {
			p.showHealing();
		}
		if (hp === 0) {
			p.showDeath();
		}
		if (p.hp === 0 && p.hp < hp) {
			p.showRespawn();
		}

		state.updateHP(id, hp);
	};

	room.onMessage('joined', message => {
		Notifications.joinedTeam(state, message);
	});

	room.onMessage('leave', message => {
		Notifications.playerDisconnected(state, message);
	});

	room.onMessage('frag', message => {
		Notifications.logFrag(state, message);
	});

	room.onMessage('capture', message => {
		Notifications.logCapture(state, message);
	});

	room.onMessage('defence', message => {
		Notifications.logDefence(state, message);
	});

	room.onMessage('medallion_picked', message => {
		Notifications.logMedallionPicked(state, message);
	});

	room.onMessage('won', message => {
		Notifications.logWin(state, message);
	});

	room.onMessage('round_start', () => {
		Notifications.roundStart();
	});

	room.state.orbs.onAdd = (orb: Orb, key: number) => {
		state.addOrb(orb, key);
		orb.onChange = changes => {
			changes.forEach(change => {
				switch (change.field) {
					case 'position':
						break;
					case 'hp':
						state.updateOrbHP(key, change.value);
						break;
					case 'team':
						state.switchOrbTeam(key, change.value);
						break;
					default:
						console.log(change);
				}
			});
		};
	};

	room.state.medallions.onAdd = (medallion: Medallion, key: number) => {
		state.addMedallion(medallion, key);
		medallion.onChange = changes => {
			changes.forEach(change => {
				switch (change.field) {
					case 'position':
						break;
					case 'medallion_type':
						state.updateMedallionType(key, change.value);
						break;
					default:
						console.log(change);
				}
			});
		};
	};

	room.state.onChange = (changes: any) => {
		changes.forEach((change: any) => {
			switch (change.field) {
				case 'red_points':
					state.setVictoryPoints('red', change.value);
					break;
				case 'blue_points':
					state.setVictoryPoints('blue', change.value);
					break;
				case 'game_countdown':
					state.hud.setGameCountdown(change.value);
					break;
				case 'game_state':
					state.setGameState(change.value);
					break;
				default:
					break;
			}
		});
	};

	room.state.players.onAdd = (player: Player, key: string) => {
		const isMe = room.sessionId === key;
		state.addPlayer(key, player, isMe);

		player.onChange = changes => {
			const p: PlayerSprite = state.players[key];
			changes.forEach(change => {
				switch (change.field) {
					case 'spells':
					case 'fireballs':
					case 'boulders':
					case 'portals':
						break;
					case 'direction':
						p.direction = change.value;
						break;
					case 'position':
						state.scene.movePlayer(p, change.value.x, change.value.y);
						break;
					case 'is_moving':
						p.is_moving = change.value;
						break;
					case 'hp':
						updateHP(key, p, change.value);
						break;
					case 'last_attack_primary':
						if (change.value > loaded) {
							p.setLastAttackPrimary(change.value);
						}
						break;
					case 'last_attack_secondary':
						if (change.value > loaded) {
							p.setLastAttackSecondary(change.value);
						}
						break;
					case 'charge':
						p.setCharge(change.value);
						if (isMe) {
							state.hud.setCharge(change.value);
						}
						break;
					case 'build':
						state.updateBuild(key, change.value);
						break;
					case 'is_cloaked':
						p.is_cloaked = change.value;
						break;
					case 'frozen':
						p.frozen = change.value;
						break;
					case 'fire_ammo':
						if (isMe) {
							state.hud.setFireAmmo(change.value);
						}
						break;
					case 'stamina':
						state.updateStamina(key, change.value);
						break;
					case 'mine':
						if (change.value) {
							if (change.value.detonated) {
								state.explodeMine(p.id);
							} else {
								state.addMine(p.id, change.value, p.team);
							}
						} else {
							state.removeMine(p.id);
						}
						break;
					case 'rushing':
						if (change.value) {
							// p.sprite.setTint(p.team === 'red' ? 0x0 : 0x0);
						} else {
							// p.sprite.clearTint();
						}
						break;
					case 'vacuuming':
						p.setVacuuming(change.value);
						break;
					case 'is_holding':
						p.is_holding = change.value;
						break;
					case 'is_held':
						p.is_held = change.value;
						break;
					case 'medallion':
						p.setMedallion(change.value);
						break;
					case 'held_medallion':
						if (isMe) {
							state.hud.setHeldMedallion(change.value);
						}
						break;
					case 'invulnerable':
						state.setInvulnerable(p, change.value);
						break;
					case 'invuln_start':
						state.setInvulnerableRune(p, change.value);
						break;
					case 'lastRelease':
						p.last_release = change.value;
						p.setChargingSecondary(false);
						break;
					case 'projectiles':
					case 'id':
						break;
					case 'className':
						state.editClass(key, change.value);
						break;
					case 'respawn_timer':
						if (isMe) {
							state.hud.setRespawnTimer(change.value);
						}
						break;
					default:
						console.log(change);
				}
			});
		};

		if (player.className === 'bord') {
			const bord = player as Bord;
			Object.entries(bord.boulders).forEach(([b_id, boulder]) => {
				state.addBoulder(key + b_id, boulder, bord.team);
			});

			bord.boulders.onAdd = (boulder, b_id) =>
				state.addBoulder(key + b_id, boulder, bord.team);
			bord.boulders.onChange = (boulder, b_id) =>
				state.updateBoulder(key + b_id, boulder);
			bord.boulders.onRemove = (_, b_id) => state.removeBoulder(key + b_id);
		}

		if (player.className === 'fjord') {
			const fjord = player as Fjord;
			// TODO: As below, also a bandaid.
			if (fjord.mine) {
				state.addMine(key, fjord.mine, fjord.team);
			}

			fjord.projectiles.onAdd = (proj, projId) => {
				state.addProjectile(key + projId, proj);
			};
			fjord.projectiles.onChange = (proj, projId) => {
				state.updateProjectile(key + projId, proj);
			};
			fjord.projectiles.onRemove = (proj, projId) => {
				state.removeProjectile(key + projId);
			};
		}

		if (player.className === 'word') {
			const word = player as Word;
			// TODO: This is a bandaid. Ideally we'd have a way to trigger these
			// updates automatically when a new player joins and when updates happen.
			Object.entries(word.portals).forEach(([p_id, portal]) => {
				state.addPortal(key + p_id, portal, word.team);
			});

			word.spells.onAdd = (spell, spellId) => {
				state.addSpell(key + spellId, spell, player.team);
			};
			word.spells.onRemove = (spell, spellId) => {
				state.removeSpell(key + spellId);
			};
			word.fireballs.onAdd = (fb, fb_id) => {
				state.addFireball(key + fb_id, fb);
			};
			word.fireballs.onChange = (fb, fb_id) => {
				state.updateFireball(key + fb_id, fb);
			};
			word.fireballs.onRemove = (fb, fb_id) => {
				state.removeFireball(key + fb_id);
			};
			word.portals.onAdd = (portal, p_id) => {
				state.addPortal(key + p_id, portal, word.team);
			};
			word.portals.onChange = (portal, p_id) => {
				state.updatePortal(key + p_id, portal);
			};
			word.portals.onRemove = (portal, p_id) => {
				state.removePortal(key + p_id);
			};
		}
	};

	room.state.players.onRemove = (player: Player, key: string) => {
		state.removePlayer(key);
	};

	return {
		room,
		joinBattle: data => {
			room.send(MESSAGES.join, data);
		}
	} as BattleClient;
}
