import { Schema, type } from '@colyseus/schema';

/**
 * A 2D vector class to represent arena position.
 */
class Vector extends Schema {
	@type('number')
	public x: number;
	@type('number')
	public y: number;

	constructor(x: number, y: number) {
		super();
		this.x = x;
		this.y = y;
	}

	public set(v: Vector) {
		this.x = v.x;
		this.y = v.y;
		return this;
	}

	public setXY(x: number, y: number) {
		this.x = x;
		this.y = y;
		return this;
	}

	public norm() {
		const l = Math.sqrt(this.x * this.x + this.y * this.y);
		if (l === 0) {
			throw new Error('norm() tried to normalize a zero vector');
		}
		return new Vector(this.x / l, this.y / l);
	}

	normalize() {
		const l = Math.sqrt(this.x * this.x + this.y * this.y);
		if (l === 0) {
			throw new Error('normalize() tried to normalize a zero vector');
		}
		this.x /= l;
		this.y /= l;
		return this;
	}

	public add(v: Vector) {
		this.x += v.x;
		this.y += v.y;
		return this;
	}

	public sub(v: Vector) {
		this.x -= v.x;
		this.y -= v.y;
		return this;
	}

	public multiply(scalar: number) {
		this.x *= scalar;
		this.y *= scalar;
		return this;
	}

	public squaredDist(v: Vector) {
		const x = this.x - v.x;
		const y = this.y - v.y;
		return x ** 2 + y ** 2;
	}

	length() {
		return Math.sqrt(this.x ** 2 + this.y ** 2);
	}

	public dot(v: Vector) {
		return this.x * v.x + this.y * v.y;
	}

	public angleBetween(v: Vector) {
		return Math.atan2(this.y - v.y, this.x - v.x) - Math.PI / 2;
	}
}

export const tempVec1 = new Vector(0, 0);
export const tempVec2 = new Vector(0, 0);
export const tempVec3 = new Vector(0, 0);

export default Vector;
